<template>
  <main>
    Baulösungen
  </main>
</template>

<script>
export default {
  name: 'PageConstructionSolutions',
}
</script>
